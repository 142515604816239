<template>
	<div class="">
		<center-title title="保存方案"></center-title>

		<router-link to="/selectmygoodslist" class="el-button whtie submit" 
      >产品浏览</router-link>

    
      <router-link to="/mygoodslist" class="el-button whtie submit"  style="margin-bottom: 2%;"
      >产品方案</router-link>
	  <router-link to="/existinglist" class="el-button whtie submit"  style="margin-bottom: 2%;"
      >临时产品方案</router-link>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="162px" class="ruleForm">
			<el-form-item label="产品方案标题" prop="product_set_name" class="mb30">
				<el-input v-model="ruleForm.product_set_name" placeholder="请输入产品方案标题" :disabled="acountMode == 'View'"></el-input>
			</el-form-item>
			<!-- <el-form-item label="图片上传" prop="img" class="mb30">
				<el-input v-model="ruleForm.img" placeholder="请上传商品集图片" :disabled="acountMode == 'View'"></el-input>
			</el-form-item>
			<el-form-item label="商品集简介" prop="notes" class="mb30">
				<el-input v-model="ruleForm.notes" placeholder="请输入商品集简介" :disabled="acountMode == 'View'"></el-input>
			</el-form-item> -->
			<el-form-item label="客户联系名称" prop="contacts" class="mb30">
				<el-input v-model="ruleForm.contacts" placeholder="请输入联系人" :disabled="acountMode == 'View'"></el-input>
			</el-form-item>
			<el-form-item label="客户手机号" prop="mobile" class="mb30">
				<el-input v-model="ruleForm.mobile" placeholder="请输入手机号" :disabled="acountMode == 'View'"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" class="white md bg-primary" @click="submitForm('ruleForm')">
					{{ acountMode == 'add' ? '新增' : '保存' }}
				</el-button>
				<el-button class="reset bg-center white md" @click="resetForm('ruleForm')" v-if="acountMode != 'edit'">
					重置
				</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
	import CenterTitle from "../components/CenterTitle";
	import {
		vxRule
	} from "@/utils/validator";
	export default {
		name: "",
		props: {},
		components: {
			"center-title": CenterTitle,
		},
		data() {
			return {
				acountMode: 'add',
				ruleForm: {
				  approvalType: "",
				  earlyApproval: "",
				  finalApproval: ""
				},
				rules: {
				  approvalType: vxRule(true, null, "change", "请输入产品方案标题"),
				  approvalType: vxRule(true, null, "change", "请输入联系人"),
				  approvalType: vxRule(true, null, "change", "请输入手机号"),
				},
				approvalTypeLists: [],
				managerLists: [],
			};
		},
		computed: {},
		watch: {},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let api = (this.acountMode == 'add' ? this.$api.addGoodsListApi : this.$api
							.editApprovalApi)

						api(this.ruleForm).then((res) => {
							if (res.code == 1) {
								this.$message({
									message: res.msg,
									type: "success",
									onClose: () => {
										//this.$router.go(-1);
										this.$router.push('/mygoodslist')
									},
								});
							}
						});
					} else {
						return false;
					}
				});
			},
			
		},
		
		created() {
		
		},
		mounted() {},
		beforeDestroy() {},
	};
</script>
<style lang='scss' scoped>
	/deep/ .image-slot {
		font-size: 28px;
		color: #ccc;
	}

	.el-checkbox {
		height: 48px;
		line-height: 48px;
	}

	.info-avatar {
		width: 90px;
		height: 90px;
		margin-right: 25px;
		border-radius: 50%;
		overflow: hidden;
		background-color: #f5f7fa;
		border: 1px solid #f5f7fa;

		.avatar {
			width: 90px;
			height: 90px;
		}

		.tips {
			bottom: 0;
			height: 25px;
			left: 0;
			right: 0;
			line-height: 20px;
			text-align: center;
			background-color: rgba($color: #000000, $alpha: 0.6);
		}
	}

	/deep/ .el-form {
		.el-select {
			width: 100%;
		}

		.el-button,
		.el-form-item__label,
		.el-input__inner {
			height: 48px;
			line-height: 48px;
			font-size: 16px;
		}

		.el-button,
		.reset {
			width: 150px;
			margin: 10px auto 0;
			padding: 0;
		}

		.reset {
			margin-left: 30px;
			border: none;
			background-color: $-color-border;
		}
	}

	.el-select-dropdown__item {
		font-size: 16px;
		height: 44px;
		line-height: 44px;
	}
</style>
